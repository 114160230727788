import "./App.css";
import { RootNavigation } from "./components";


function App() {
  return (
    <>
        <RootNavigation />
    </>
  );
}

export default App;

// FIGMA:
// https://www.figma.com/file/cC9EwDeOXMNIYZaQPtEjX4/360-Kitchen?type=design&node-id=0%3A1&mode=design&t=xHNdFg59EgCcZkrN-1
